import React, { useState } from "react";
import styles from "./nav.module.css";
import { Hamburger, Logo, Xicon } from "../icons";
import { Link } from "react-router-dom";
const Nav: React.FC = () => {
  const [active, setActive] = useState<boolean>(false);

  const toggleNav = () => {
    setActive(!active);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("getstarted");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <div>
          <Link to={"/"}>
            <Logo className={styles.nav_logo} />
          </Link>
          BaigeWallet
        </div>
        <ul className={`${styles.nav_menu} ${active ? `${styles.active}` : ""}`}>
          {/* a tags will be replaced by the router links */}
          {/* <li className={styles.nav_item}>
            <a href="/">Log in</a>
          </li> */}
          <li className={`${styles.nav_item} ${styles.bg_orange}`} onClick={handleClickScroll}>
            Get started
          </li>
        </ul>
        <div className={styles.icon} onClick={toggleNav}>
          {active ? <Xicon /> : <Hamburger />}
        </div>
      </nav>
    </header>
  );
};

export default Nav;
