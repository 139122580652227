import React from "react";
import styles from "./index.module.css";
const PrivacyPolicy = () => {
  return (
    <div className={styles.terms}>
      <h2>PRIVACY POLICY</h2>
      <p>
        BaigeWallet Privacy Policy describes your privacy rights regarding how we gather, process, use,
        disclose, and protect your information, including your personal data. This Policy applies to the
        personal data collected by BaigeWallet when you use our website, mobile application, and social media
        platforms. This Policy may be reviewed from time to time, and whenever there is an update in our
        privacy policy, we will attach the amendment date to it.
      </p>
      <p>
        By accessing or using BaigeWallet Service, you agree to the collection and use of information in
        accordance with this privacy policy.
      </p>

      <h3>The objectives of this policy include:</h3>
      <ul>
        <li>
          <p>
            Ensuring that you are informed and understand what personal data we collect about you, the reasons
            for the collection of your personal data, the extent of our use of your personal data, and the
            entities we share your data with.
          </p>
        </li>
        <li>
          <p>
            Explaining the way we use the personal data you share with us in order to give you a great
            experience when you use the Baigewallet App.
          </p>
        </li>
        <li>
          <p>
            Explaining your rights and choices in relation to the personal data we collect and process about
            you and how we will protect your privacy.
          </p>
        </li>
      </ul>

      <p>
        From time to time, we may develop new or offer additional services on the application. If the
        introduction of these new or additional services results in any material changes to the way we collect
        or process your personal data, we will provide you with more information or additional terms or
        policies. Unless stated otherwise, when we introduce these new or additional services, they will be
        subject to this Privacy Policy. We hope this helps you to understand our privacy commitments to you.
      </p>

      <p>
        For further clarifications of the terms used in this Policy, please email us at{" "}
        <a href="mailto:support@baigewallet.com">support@baigewallet.com</a> Alternatively, if you do not
        agree with the content of this Policy, then please remember that it is your choice whether you want to
        use the Product and Services and you may voluntarily opt out of this service and discontinue its use.
      </p>

      <h3>Your Privacy Rights</h3>
      <p>
        You have certain rights in relation to the personal information we collect as provided by the Nigerian
        Data Protection Regulation 2019 (NDPR) and other applicable data protection laws/regulations. These
        include the right to:
      </p>
      <ul>
        <li>
          <p>access your personal data;</p>
        </li>
        <li>
          <p>rectify/update your information in our possession;</p>
        </li>
        <li>
          <p>
            withdraw your consent to the processing of personal information. This will however not affect the
            legality of processing carried out prior to the withdrawal of consent;
          </p>
        </li>
        <li>
          <p>
            object to processing of personal information (ways in which we are using your personal
            information, including direct marketing). This will only be applicable where there are no legal or
            operational reasons;
          </p>
        </li>
        <li>
          <p>
            request that your personal information be made available to you in a common electronic format
            and/or request that such data be sent to a third party;
          </p>
        </li>
        <li>
          <p>
            request that your information be erased. However, we are obligated to retain such data if there
            are valid legal, regulatory, or operational reasons to do so;
          </p>
        </li>
        <li>
          <p>Request that we restrict the processing of your personal information;</p>
        </li>
        <li>
          <p>
            not to be subject to a decision based solely on automated decision-making, including profiling,
            where the decision would have a legal effect on you or produce a similarly significant effect.
          </p>
        </li>
      </ul>

      <p>
        The exercise of these rights is dependent on many factors. In certain instances, we may not be able to
        comply with these requests because we have legitimate grounds for the refusal or where the right is
        not applicable to the particular data obtained from the individual in question. These rights are also
        subject to the limitations provided in the NDPR and other applicable data protection laws/regulations.
      </p>
      <p>
        If you have any questions about your privacy, your rights, or how to exercise them, please refer to
        the Contact Us section of this policy.
      </p>

      <h3>THE TYPE OF PERSONAL INFORMATION WE COLLECT FROM YOU</h3>
      <p>
        We currently collect and process different categories of personal information for various purposes to
        provide and improve the services we offer to you. To enable you to sign up for and use the BaigeWallet
        App, we collect the following personal data from you:
      </p>
      <ul>
        <li>
          <p>
            Personal identifiers – this includes your name, username, email address, phone number, birth date,
            and gender.
          </p>
        </li>
        <li>
          <p>
            Transaction details – this includes your BaigeWallet transaction history, wallet ID, and
            information you provide for the delivery of services to you.
          </p>
        </li>
        <li>
          <p>
            Information about any of our products and services you currently use and have, or products and
            services you have applied for or have previously used.
          </p>
        </li>
        <li>
          <p>
            User Content – this includes content you post to the BaigeWallet account such as your contact list
            and location data. Please note that we will only access your contact list, current location,
            camera, or photos from your device if you give us permission to do so, and we will only access
            images that you specifically choose to share with us and metadata related to those images, such as
            the type of file and the size of the image. We will never scan or import your device’s photo
            library or camera roll.
          </p>
        </li>
        <li>
          <p>
            Inferences drawn about your interests and preferences based on your usage of the BaigeWallet App.
          </p>
        </li>
        <li>
          <p>Information about your interactions with the BaigeWallet App.</p>
        </li>
        <li>
          <p>
            Certain technical data – this may include URL information, online identifiers including cookie
            data and IP addresses, smart device information (including mobile device unique ID, your mobile
            operating system and other diagnostic data), mobile network provider, browser type, language and
            your non-precise location (which may be derived or inferred from certain technical data like your
            IP address and the language setting of your phone or payment currency). We do not store or upload
            this information to our servers. We only collect what we need to improve your experience.
          </p>
        </li>
      </ul>

      <p>
        We may also process specific categories of information for specific and limited purposes, such as
        detecting and preventing financial crime or making our services available to customers. We will only
        process particular types of information after we have obtained your explicit consent or we are
        otherwise lawfully permitted to do so.
      </p>

      <h3>How We Gather Personal Information and why we have it.</h3>
      <p>
        Most of the personal information we process is collected or provided directly to us by you. The
        information we collect include:
      </p>
      <ol>
        <li>
          <p>
            Information collected or provided directly to us by you to enable you sign up for and use the
            BaigeWallet App;
          </p>
        </li>
        <li>
          <p>
            Information collected about you when you are accessing and/or using the BaigeWallet app such as
            your location data, browser settings, your IP address and other online identifiers;
          </p>
        </li>
        <li>
          <p>
            Information that we learn about you through our relationship with you and the way you operate your
            accounts and or services, such as transactions made to and from your wallet;
          </p>
        </li>
        <li>
          <p>
            We also receive personal information indirectly, from the following sources in the following
            scenarios: Information that we receive from various third parties – third parties who provide
            services to you or us, including merchants, credit reference, fraud prevention or government
            agencies and other banks;
          </p>
        </li>

        <li>
          <p>
            Information we collect from publicly available sources, such as the press, company registers and
            online search engines.
          </p>
        </li>
      </ol>
      <p>
        In the table below, we have set out the reasons for our processing of your personal information and
        the associated legal bases that we rely upon as provided in the Nigerian Data Protection Regulation
        (NDPR) and other applicable data protection laws/regulations.
      </p>

      <table>
        <thead>
          <tr>
            <td>Processing Purpose</td>
            <td>Lawful Basis under NDPR</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>To Provide and personalise the BaigeWallet App</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Performance of a contract</p>
                </li>
                <li>
                  <p>Legitimate Interest</p>
                </li>
                <li>
                  <p>Consent</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>To understand, diagnose, troubleshoot and fix issues with the BaigeWallet App</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Performance of a contract</p>
                </li>
                <li>
                  <p>Legitimate Interest</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>To improve product features and design</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Legitimate Interest</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                To comply with and enforce applicable legal and regulatory requirements, relevant industry
                standards, contractual obligations and our internal policies.
              </p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Compliance with legal obligations</p>
                </li>
                <li>
                  <p>Legitimate Interest</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>To verify your identity when you access your account</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Performance of a contract</p>
                </li>
                <li>
                  <p>Compliance with legal obligations</p>
                </li>
                <li>
                  <p>Legitimate Interest</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>To establish, exercise or defend legal claims</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Legitimate Interest</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>To carry our business research, analytics, planning and forecasting</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Legitimate Interest</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>To detect fraud, money laundering or terrorism financing activities</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Performance of a contract</p>
                </li>
                <li>
                  <p>Compliance with legal obligations</p>
                </li>
                <li>
                  <p>Legitimate Interest</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>Manage our risks, review your credit or loan eligibility</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Performance of a contract</p>
                </li>
                <li>
                  <p>Legitimate Interest</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>Respond to your requests and communicate with you</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Consent</p>
                </li>

                <li>
                  <p>Performance of a contract</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>To check your wallet balance and transaction history.</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Performance of a contract</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              To help recover funds that may have entered your account as a result of a payment sent in error
            </td>
            <td>
              <ul>
                <li>
                  <p>Compliance with legal obligations</p>
                </li>
                <li>
                  <p>Legitimate Interest</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>To conduct research, contests, surveys and Sweepstakes</p>
            </td>
            <td>
              <ul>
                <li>
                  <p>Consent</p>
                </li>

                <li>
                  <p>Legitimate Interest</p>
                </li>
                <li>
                  <p>Performance of a contract</p>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Sharing your personal information</h3>
      <p>
        We share personal information about you and your dealings with us, to the extent permitted by law,
        with the following:
      </p>
      <ol>
        <li>
          <p>
            Legal/Regulatory Authorities - We may disclose personal information if we determine that for the
            purposes of national security, law enforcement, or other issues of public importance, a disclosure
            of personal information is necessary or appropriate. It may be necessary by law, legal process,
            litigation, and/or requests from public and governmental authorities for the disclosure of your
            personal information.
          </p>
        </li>
        <li>
          <p>Professional Advisers: Auditors, Legal Advisers, et al.</p>
        </li>
        <li>
          <p>Credit Agencies;</p>
        </li>
        <li>
          <p>Correspondent Banks;</p>
        </li>
        <li>
          <p>External Auditors;</p>
        </li>
        <li>
          <p>
            Strategic partners/service providers – we provide personal data to trusted and authorised third
            parties who assist us in conducting our business and/or providing services to you. We require that
            these parties agree to process such information based on our instructions and in compliance with
            this Privacy Policy and any other appropriate confidentiality and security measures.
          </p>
        </li>
      </ol>

      <p>
        We also disclose your Personal Information in good faith when we believe it is necessary for us to do
        so in any of the following circumstances:
      </p>
      <ol>
        <li>
          <p>We have your consent to share the information.</p>
        </li>
        <li>
          <p>To comply with a legal obligation.</p>
        </li>
        <li>
          <p>To prevent or investigate possible wrongdoing in connection with our Service.</p>
        </li>
        <li>
          <p>To protect the personal safety of users of our Service or the public.</p>
        </li>
        <li>
          <p>To protect against legal liability.</p>
        </li>
        <li>
          <p>
            In the event of a reorganization, merger, or sale, we may transfer any and all personal
            information we collect to the relevant third party.
          </p>
        </li>
        <li>
          <p>
            We find that your actions on our website or application violate any part of our Terms and
            Conditions of use.
          </p>
        </li>
      </ol>

      <p>
        Whenever we share your personal information with anyone other than for purposes highlighted herein, we
        will inform you about the details of the exchange. We require that all third parties with whom we
        share your personal data agree to process such information based on our instructions and in compliance
        with this privacy policy and any other appropriate confidentiality and security measures.
      </p>
      <p>
        Special categories of personal data (also known as sensitive personal data) are part of the
        information we collect from you. In particular, we may process biometric data (such as facial and
        voice recognition), for services that require it. Where we process such sensitive personal data, we
        will do so on the basis that we have your explicit consent to do so, and that it is necessary for
        reasons of substantial public interest, to establish, and/or to exercise or defend any legal claims.
        We will carry out processing under applicable laws.
      </p>

      <h3>How long we will keep your information.</h3>
      <p>
        We will keep your personal data for as long as we have a relationship with you, i.e., for as long as
        you use the app, remain validly subscribed to a newsletter or partake in surveys. Following the
        termination of our relationship with you, (e.g., following the deletion of your account) or
        cancellation of your subscription to the Service), we will cease to gather or collect any new
        information about you.
      </p>
      <p>
        Once the relationship is terminated, we will only retain personal data for as long as is necessary,
        depending on the type of personal information and the purposes for which that information is retained.
        Please, note that retention periods are subject to legal and regulatory requirements.
      </p>

      <h3>How Do We Protect Your Information?</h3>
      <p>
        We implement a variety of reasonable security measures to protect the security and integrity of your
        personal information. We use computer safeguards such as firewalls and data encryption, enforce
        physical access to our buildings and files and only authorise access to personal information to only
        employees who require the data to fulfill their job responsibilities, or our affiliates as may be
        required in the provision of our services to you. However, the internet is not always a secure medium,
        and we cannot guarantee the non-occurrence of security breaches. Accordingly, we are not responsible
        for matters, which include actions of hackers and other unauthorized third parties that attempt to
        breach our reasonable security procedures. No method of electronic transmission or storage is 100%
        secure, therefore, we cannot guarantee the absolute security of your Personal Information. It is
        important that you understand that you also play a crucial role in protecting your personal
        information.
      </p>
      <p>
        Please safeguard your passcodes for your BaigeWallet account and app and do not share them with
        others. If we receive instructions using your BaigeWallet account login information, we will consider
        that you have authorized the instruction. You agree to notify us immediately of any unauthorized use
        of your BaigeWallet account or any other breach of security. We reserve the right, at our sole
        discretion, to refuse to provide our Services, terminate BaigeWallet accounts, and to remove or edit
        content.
      </p>

      <h3>Third-Party Policy</h3>
      <p>
        Occasionally, at our discretion, we may include or offer third-party products or services or links on
        our application. These third-party sites have separate and independent privacy policies. We request
        that you separately review these policies, as we, have no responsibility or liability for the content
        and activities of linked platforms. Nonetheless, we seek to protect the integrity of our application
        and welcome feedback about these sites.
      </p>

      <h3>Cookie Policy</h3>
      <p>
        Cookies help us serve you better. Think of cookies as crumbs. Every time you visit a website, that
        website saves bits of your visit so they can keep track of how many times you have visited, the length
        of time spent, and your activities. This information helps the site show you things that are relevant
        to you based on your past visits. The length of time stored depends on the cookie, but this is
        generally at least a few minutes and up to two years.
      </p>

      <h3>Why and How We Use Cookies on Our Website</h3>
      <p>
        We use cookies to enhance our customers’ online activity by making navigation easier and providing
        important security features. For the provision of certain services, cookies are a requirement because
        they help protect customer privacy. For example, cookies automatically terminate the online session if
        the customer forgets to log out. Visitors to our website who have JavaScript enabled are tracked using
        Google Analytics. Google Analytics collects the following types of information from users:
      </p>
      <ol>
        <li>
          <p>Web browser used – software manufacturer, and version number</p>
        </li>
        <li>
          <p>Operating system</p>
        </li>
        <li>
          <p>Screen colors</p>
        </li>
        <li>
          <p>JavaScript support</p>
        </li>
        <li>
          <p>Flash version</p>
        </li>
        <li>
          <p>Screen resolution</p>
        </li>
        <li>
          <p>Network location and IP address:</p>
        </li>
        <ul>
          <li>
            <p>May include geographic</p>
          </li>
          <li>
            <p>Hostname</p>
          </li>
          <li>
            <p>Connection Bandwidth</p>
          </li>
        </ul>
        <li>
          <p>Time of visit</p>
        </li>
        <li>
          <p>Pages visited and dwell time on these pages</p>
        </li>
        <li>
          <p>For referring sites:</p>
        </li>
        <ul>
          <li>
            <p>The referring website URL</p>
          </li>
          <li>
            <p>Search engine query used</p>
          </li>
        </ul>
      </ol>
      <p>
        Google Analytics data is shared with Google. For more information on Google’s Privacy Policies, visit{" "}
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
      </p>

      <h3>Third-party Cookies</h3>
      <p>
        We allow only our trusted third parties to have their cookies on our website. BaigeWallet uses these
        types of cookies:
      </p>
      <ul>
        <li>
          <p>Strictly Necessary Cookies</p>
        </li>
        <li>
          <p>Performance Cookies</p>
        </li>
        <li>
          <p>Functionality Cookies</p>
        </li>
      </ul>
      <p>
        You can turn functionality cookies off whenever you like. You can choose to have your computer issue a
        notification you each time a cookie is being sent, or you can choose to turn off unnecessary cookies.
        The privacy settings o your device will determine the ay to limit the use of cookies. If you turn your
        cookies off, some of the features that make your site experience more efficient may not function
        properly.
      </p>

      <h3>Information Shared Socially</h3>
      <p>
        We are not responsible for maintaining the confidentiality of any information you share publicly or
        with friends. Please be mindful of your personal privacy needs and the privacy needs of others, as you
        choose whom to connect with and what to share and make public. We cannot control the privacy or
        security of information you choose to make public or share with others. We also do not control the
        privacy practices of third-party websites. Please contact those sites and services directly if you
        want to learn about their privacy practices.
      </p>

      <h3>Transfer to Other Countries</h3>
      <p>
        Personal data collected within Nigeria may be transferred to and processed by third parties located in
        other countries. In such instances, We shall ensure that the transfer of your personal data is carried
        out in accordance with applicable privacy laws/regulations and, in particular, that appropriate
        contractual, technical, and organizational measures are in place, such as adequacy mechanisms and
        standard contractual clauses approved by the Attorney General of the Federation. For further details
        of the security measures we use to protect your personal data, please see the “How do we protect your
        personal information” section of this policy.
      </p>

      <h3>Changes to this policy</h3>
      <p>
        Based on the changing nature of privacy laws, user needs and our business, we may modify this Privacy
        Policy from time to time. Any change to our Privacy Policy will be communicated on our upgrades via
        the application, and this will be effective as soon as published. Accordingly, we encourage periodic
        reviews of this Privacy Policy for awareness of any changes that may have occurred.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any queries about how we use your personal information which is not answered here, you may
        contact us at <a href="mailto:support@baigewallet.com">support@baigewallet.com</a>
      </p>
    </div>
  );
};

const Terms: React.FC = () => <PrivacyPolicy />;

export default Terms;
