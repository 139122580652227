import React from "react";
import { FinancialPlans, Process, Faq, GetStarted, HeroSection, About } from "../../components/HomeSections";

const Home: React.FC = () => {
  return (
    <React.Fragment>
      <HeroSection />
      <About />
      <FinancialPlans />
      <Process />
      {/* <Testimonials /> */}
      <Faq />
      <GetStarted />
    </React.Fragment>
  );
};

export default Home;
