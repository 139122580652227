import React from "react";

import styles from "./testimonials.module.css";

import userImg from "../../../assets/images/female-copywriter.png";
import { Headings } from "../../Layout";

export const testimonial = [
  {
    id: 1,
    role: "Program Manager",
    name: "Kemi Adeola",
    img: userImg,
    testimony:
      "“I had an awesome experience with baige, very reliable n trustworthy, infact d just save is d tip of d iceberg. I hereby recommend this savings APP among apps...BAIGE to all prospective savers, u will never be disappointed.",
  },
  {
    id: 2,
    role: "Software Engineer",
    name: "Zimuzo Obi",
    img: userImg,
    testimony:
      "“I had an awesome experience with baige, very reliable n trustworthy, infact d just save is d tip of d iceberg. I hereby recommend this savings APP among apps...BAIGE to all prospective savers, u will never be disappointed.",
  },
  {
    id: 3,
    role: "CTO Afrima",
    name: "Gbenga Ayodele",
    img: userImg,
    testimony:
      "“I had an awesome experience with baige, very reliable n trustworthy, infact d just save is d tip of d iceberg. I hereby recommend this savings APP among apps...BAIGE to all prospective savers, u will never be disappointed.",
  },
  {
    id: 4,
    role: "Devops Engineer",
    name: "Kemi Adetiba",
    img: userImg,
    testimony:
      "“I had an awesome experience with baige, very reliable n trustworthy, infact d just save is d tip of d iceberg. I hereby recommend this savings APP among apps...BAIGE to all prospective savers, u will never be disappointed.",
  },
  {
    id: 5,
    role: "Product Designer",
    name: "Ayodeji Baba",
    img: userImg,
    testimony:
      "“I had an awesome experience with baige, very reliable n trustworthy, infact d just save is d tip of d iceberg. I hereby recommend this savings APP among apps...BAIGE to all prospective savers, u will never be disappointed.",
  },
];

const faq = [
  {
    id: 1,
    question: "What is BaigeWallet?",
    answer:
      "BaigeWallet is a savings and investment platform that offers financial growth opportunities in a safe and convenient manner at highly competitive rates.  BaigeWallet enables you to grow and secure your wealth through various products for favorable returns.",
  },
  {
    id: 2,
    question: "How can I start Saving/Investing on BaigeWallet?",
    answer: (
      <>
        <p>To start saving on BaigeWallet, download the mobile app on Google Play store or IOS.</p>
        <p>Sign up to register on the website. Fill in the required details (Email, Phone number etc).</p>
        <p>
          Create a password, then link your Bank Verification Number (BVN) to your BaigeWallet profile. Start funding
          your Wallet by adding your bank details after successfully onboarding and setting up a plan. You can fund your
          wallet with your Debit Card (All debit Cards issued by Nigerian Banks are enabled on our platform), Bank
          Transfer or USSD code.
        </p>
      </>
    ),
  },
  {
    id: 3,
    question: "How secure is my Savings/Investment on BaigeWallet?",
    answer: (
      <p>
        BaigeWallet has multiple security layers to ensure your funds are safe and accessible at withdrawal. Customer’s
        Card-related information are handled by our partner Payment Processor who is PCI-DSS compliant. BaigeWallet uses
        the highest standard globally for Card data protection in payment processing. BaigeWallet provides Token-based
        2FA as an additional degree of security for users. In addition to using passwords and PINs, this is applicable
        when authorizing transactions using one-time codes (tokens). Only devices with 2FA enabled can receive this
        token.
      </p>
    ),
  },
  {
    id: 4,
    question: "What is OTP?",
    answer: (
      <p>This is a one-time password used for initiating transfers and withdrawals on your BaigeWallet account.</p>
    ),
  },
  {
    id: 5,
    question: "How much can I save on BaigeWallet?",
    answer: (
      <p>
        With a minimum of N2,000, you can start saving on BaigeWallet. You can also choose how much you want to save or
        invest from our various plans on the app.
      </p>
    ),
  },
  {
    id: 6,
    question: "How is BaigeWallet able to afford higher returns?",
    answer: (
      <p>
        We adopt a lean operational structure that enables us to be cost-efficient in comparison to conventional Banks.
        We leverage on technology to deliver premium customer experience. Investor funds are managed by SEC-regulated
        Asset Management firms and are primarily invested in low-risk securities issued by the Central Bank of Nigeria
        and Nigeria’s Debt Management Office (DMO).
      </p>
    ),
  },

  {
    id: 7,
    question: "Is BVN compulsory for signing up on Baige wallet?",
    answer: (
      <p>
        Your BVN is required to sign up on Baige wallet because it enables us to identify you by your name, phone
        number, and date of birth. It is also a key component of customer protection as it ensures you can only withdraw
        funds to your bank account. This means that no one can withdraw your funds on your behalf to a third-party
        account.
      </p>
    ),
  },
  {
    id: 8,
    question: "What if I no longer have access to the phone number I used to register my BVN?",
    answer: <p>Kindly reach out to the Bank you registered with, to get this resolved. </p>,
  },
  {
    id: 9,
    question: "I want to link my BVN to my BaigeWallet account, but I am unable to resolve it. What should I do?",
    answer: (
      <p>
        Kindly reach out to <a href="mailto:support@baigewallet.com">support@baigewallet.com</a> for resolution.
      </p>
    ),
  },
  {
    id: 10,
    question: "Can I withdraw to any Bank Account?",
    answer: <p>You can only withdraw to your bank account that possesses the same name and BVN on your BaigeWallet.</p>,
  },
  {
    id: 11,
    question:
      "I withdrew from my BaigeWallet app but didn't receive the money in my bank account. What could be wrong?",
    answer: (
      <p>
        This might be caused by a network delay from the bank. You will receive it once the network is stable. if the
        status doesn't change after an hour, kindly reach out to{" "}
        <a href="mailto:support@baigewallet.com">support@baigewallet.com</a>
      </p>
    ),
  },
  {
    id: 12,
    question: "Can I make emergency withdrawals?    ",
    answer: (
      <p>
        The ‘Just Save’ option enables easy access to customers’ funds for withdrawal purposes, in comparison to other
        Savings options that are subject to fixed withdrawal dates/tenors.
      </p>
    ),
  },
  {
    id: 13,
    question: "Can I change the maturity date on my savings?",
    answer: (
      <p>
         Maturity dates are relative to each Savings/Investment option. Maturity dates cannot be modified once locked in
        until the expiration date after which there is an option to either rollover / withdraw. Kindly make use of the
        ‘Just Save’ option for funds you would like to easily access. 
      </p>
    ),
  },
  {
    id: 14,
    question: "The verification code does not deliver to my phone after several attempts. What should I do?",
    answer: <p>Kindly check your e-mail, it should be in your inbox, spam or Promotions folder.</p>,
  },
  {
    id: 15,
    question: "Why can't I withdraw my funds after it matures?",
    answer: (
      <p>
        If such a scenario is encountered, kindly contact{" "}
        <a href="mailto:support@baigewallet.com">support@baigewallet.com</a> or reach out to the customer care line
        listed on the website and specify the challenge as well as any errors while trying to withdraw funds?
      </p>
    ),
  },
  {
    id: 16,
    question: "Can I withdraw to any Bank Account?",
    answer: <p>You can only withdraw to your bank account that possesses the same name and BVN on your BaigeWallet.</p>,
  },
  {
    id: 17,
    question: "My account was flagged and I’m unable to withdraw. Kindly assist?",
    answer: (
      <p>
        Kindly send a message via the app live chat or contact{" "}
        <a href="mailto:support@baigewallet.com">support@baigewallet.com</a> or call our customer care line listed on
        the website.
      </p>
    ),
  },
  {
    id: 18,
    question: "I am unable to download the app. The prompt says my phone can't download the app version.",
    answer: (
      <p>
        Your phone version may not be compatible with the app. Contact customer service at baigewallet.com.  You can
        also signup and use the platform from your browser pending when the app downloads. 
      </p>
    ),
  },
  {
    id: 19,
    question: "What is Just save?",
    answer: (
      <p>
        Just save is a flexible saving option, where the customer can start saving with a minimum of N2,000 for at 12% 
        annual interest.
      </p>
    ),
  },
  {
    id: 20,
    question: "What is Save for a need?",
    answer: (
      <p>
        Save for a need enables you to save towards a target project e.g Car, house rent, school fees, etc. and earn
        interest on funds, that will be locked for a minimum of three months where the interest earned is subject to how
        long you lock the funds, Also customers are able to top up on their target savings whenever they wish to.
      </p>
    ),
  },
  {
    id: 21,
    question: "What is No Access?",
    answer: (
      <p>
        No access is a savings product where customers' funds are locked for a minimum of one month  and interest earned
        is relative to the tenor period.
      </p>
    ),
  },
  {
    id: 22,
    question: "When and how do I get interest on my BaigeWallet balance?",
    answer: <p>Interests are paid monthly, and accumulation is dependent on the amount you have in your wallet.</p>,
  },
  {
    id: 23,
    question: "Are there bank charges when I put funds in my Wallet?",
    answer: (
      <p>
        Yes! There are bank charges for transferring money to your BaigeWallet but no charges when you transfer to your
        bank account
      </p>
    ),
  },

  {
    id: 24,
    question: "Who manages BaigeWallet funds?",
    answer: (
      <p>
        No access is a savings product where customers' funds are locked for a minimum of one month  and interest earned
        is relative to the tenor period.
      </p>
    ),
  },
  {
    id: 25,
    question: "Does BaigeWallet have a physical office?",
    answer: <p>We are located at 2, Olushola Agbaje St, Lekki Phase I 106104, Lekki</p>,
  },
  {
    id: 23,
    question: "How can I reach out to your team?",
    answer: (
      <>
        <p>You can reach us through via any of these platforms </p>
        <p>
          Email: <a href="mailto:support@baigewallet.com">support@baigewallet.com</a>
        </p>
        <p>
          Contact number: <a href="tel:+2347049875121">+234 704 987 5121 </a>
        </p>
        <p>or through our live chat accessible on our mobile app and through our website www.baigewallet.com</p>
      </>
    ),
  },
];

const FaqSingle = ({ data }: { data: any }) => {
  const [showAnswer, setShowAnswer] = React.useState(false);

  return (
    <div key={data.id} id="faq">
      <div className={styles.header} onClick={() => setShowAnswer(!showAnswer)}>
        <div className={styles.title}>{data.question}</div>
        {!showAnswer ? (
          <svg
            width="20px"
            height="20px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60.364 60.364"
          >
            <g>
              <path
                d="M54.454,23.18l-18.609-0.002L35.844,5.91C35.845,2.646,33.198,0,29.934,0c-3.263,0-5.909,2.646-5.909,5.91v17.269
		L5.91,23.178C2.646,23.179,0,25.825,0,29.088c0.002,3.264,2.646,5.909,5.91,5.909h18.115v19.457c0,3.267,2.646,5.91,5.91,5.91
		c3.264,0,5.909-2.646,5.91-5.908V34.997h18.611c3.262,0,5.908-2.645,5.908-5.907C60.367,25.824,57.718,23.178,54.454,23.18z"
                fill="#fff"
              />
            </g>
          </svg>
        ) : (
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 52.161 52.161"
            width="20px"
            height="20px"
          >
            <g>
              <path
                d="M52.161,26.081c0,3.246-2.63,5.875-5.875,5.875H5.875C2.63,31.956,0,29.327,0,26.081l0,0c0-3.245,2.63-5.875,5.875-5.875
 h40.411C49.531,20.206,52.161,22.835,52.161,26.081L52.161,26.081z"
                fill="#fff"
              />
            </g>
          </svg>
        )}
      </div>
      {showAnswer && <div className={styles.answer}>{data.answer}</div>}
    </div>
  );
};
const Faq = () => {
  const [showMore, setShowMore] = React.useState(false);
  const handleClickScroll = () => {
    const element = document.getElementById("faq");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.testimonialWrapper}>
      <div className={styles.testimonials}>
        <Headings className={styles.text_center}>Frequently Asked Questions</Headings>
        <div className={styles.faqs}>
          {faq.slice(0, showMore ? faq.length : 10).map((data) => (
            <FaqSingle data={data} key={data.id} />
          ))}
        </div>
      </div>

      <button
        className={styles.button}
        onClick={() => {
          if (showMore) handleClickScroll();
          setShowMore(!showMore);
        }}
      >
        {showMore ? "Show less" : "Show More"}
      </button>
    </section>
  );
};

export { Faq };
