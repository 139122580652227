import styles from "./getStarted.module.css";
import { Button } from "../../Form/Button";
import { TextInput } from "../../Form/Input";
import { Text } from "../../Layout";
import React from "react";
import { toast } from "react-hot-toast";

const GetStarted = () => {
  const [email, setEmail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

  return (
    <section className={styles.container} id="getstarted">
      <div className={styles.get_started}>
        <h2 className={styles.getStarted_title}>
          Start <Text color="orange">saving</Text> and
          <Text color="orange"> investing</Text> today!
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (regex.test(email)) {
              setIsLoading(true);
              fetch(`https://app.baigewallet.com/api/Onboarding/send-email/${email}`)
                .then(async (data) => {
                  const res = await data.json();
                  toast.success(res.message);
                })
                .catch(async (data) => {
                  const res = await data.json();
                  toast.error(res.message);
                })
                .finally(() => {
                  setIsLoading(false);
                  setEmail("");
                });
            } else {
              toast.error("Invalid Email");
            }
          }}
          className={styles.input_container}
        >
          <TextInput
            type={"email"}
            placeholder="Please enter your email here"
            value={email}
            onChange={(e: { target: { value: React.SetStateAction<string> } }) => setEmail(e.target.value)}
          />
          <Button type="submit" className={styles.input_btn} disabled={isLoading}>
            Get Started
          </Button>
        </form>
      </div>
    </section>
  );
};

export { GetStarted };
