import styles from "./process.module.css";

import DisplayScreen from "../../../assets/images/display-screen.png";

export const processes = [
  {
    id: "01",
    title: "STEP 1",
    desc: "Download the BaigeWallet app from App Store or Play Store. Create an account by inputting your full name, email address, phone number and set password.",
  },
  {
    id: "02",
    title: "STEP 2",
    desc: "Voila! You are in! Click on FUND WALLET, enter amount (Minimum saving plan starts from N2000) input your desired amount. Choose mode of transfer. Bank transfer, Card or USSD.",
  },
  {
    id: "03",
    title: "STEP 3",
    desc: "Once your balance reflects, select a saving plan. Just Save, Save for a Need or No- Access. Your wallet will be debited and you will have a new savings balance to reflect your interest.",
  },
];

const Process = () => {
  return (
    <section className={styles.process_container}>
      <div className={styles.img_display}>
        <img src={DisplayScreen} alt="Baige mobile display screen" />
      </div>

      <div className={styles.processes}>
        <h2 className={styles.heading}>
          {/* Start building a <br /> wealthy life with us */}
          Start building a wealthy and secure life with BaigeWallet in 3 easy
          steps
        </h2>

        <div className={styles.process}>
          {processes.map((process) => (
            <div key={process.id} className={styles.process_detail}>
              <div className={styles.number}>
                <p>{process.id}</p>
              </div>
              <div className={styles.process_contents}>
                <h2>{process.title}</h2>
                <p>{process.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Process };
