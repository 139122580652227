import styles from "./Layout.module.css";

interface Props {
  className?: string,
  children: React.ReactNode
}

function Block({ className = "", ...props }) {
  return <div className={styles.block + " " + className} {...props} />;
}



function Text({ className = "",  color = "black", ...props }) {
  const classNames = [styles.text_default]

  if (color === "orange") {
    classNames.push(styles.text_orange);
  }

  classNames.push(className);

  console.log(classNames);

  return <span className={classNames.join(" ")} {...props} />;
}

function Headings( {children, className, ...props}: Props) {
  return <h2 className={styles.heading + " " + className} {...props}>{children}</h2>
}
export { Block, Text, Headings };

// git config core.ignorecase false
