import styles from "./footer.module.css";

import { Text } from "../Layout";
import { Facebook, Instagram, LinkedIn, Twitter, Youtube } from "../icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <section className={styles.footer_items}>
        {/* <div className={styles.footer_item}>
          <h2 className={`${styles.brandname} ${styles.title}`}>
            <Text color="orange">Baigewallet</Text>
          </h2>
          <p>
            Baigewallet is a digital financial service platform offering a wide
            demography of users the access to premium savings and investment
            options at highly competitive rates, whilst prioritizing security
            and convenience in automating their financial and wealth management
            decisions.
          </p>
        </div> */}

        {/* THE LIST ITEMS WOULD BE LINKS. WILL BE CHANGED WHEN APPROPRIATE LINKS COME */}
        <div className={styles.footer_links}>
          <h2 className={styles.title}>About Us</h2>
          <ul>
            <li>Products</li>
            <li>Just save</li>
            <li>Save for a Need</li>
            <li>No-Access</li>
            <li>
              Inflation Protection Investment
              {/* Budget{" "}
              <Text color="orange">
                <small>coming soon</small>
              </Text> */}
            </li>
            <li>Fund Investment</li>
            <li>FAQs</li>
          </ul>
        </div>

        <div className={styles.footer_links}>
          <h2 className={styles.title}>Who we are</h2>
          <ul>
            <li>About us</li>
            <li>
              <Link to={"/privacy-policy"}>Privacy & Terms</Link>
            </li>
          </ul>
        </div>

        <div className={styles.footer_links}>
          <h2 className={styles.title}>Resources</h2>
          <ul>
            <li>Help Center</li>
          </ul>
        </div>

        <div className={styles.footer_links}>
          <h2 className={styles.title}>Contact</h2>
          <ul>
            <li>2 Olushola Agbaje St, Lekki Phase I 106104, Lekki</li>
            <li>
              <a href="mailto:support@baigewallet.com">Email: support@baigewallet.com</a>
            </li>
            <li>
              <a href="/">Web: www.baigewallet.com</a>
            </li>
            <li>
              <a href="tel:+2347049875121">Customer care line : +234 704 987 5121</a>
            </li>
          </ul>
          <div className={styles.social_icons}>
            <a href="https://instagram.com/baigewallet?igshid=NTdlMDg3MTY=" target="_blank" rel="noreferrer">
              <Instagram className={styles.icon} />
            </a>
            <a
              href="https://twitter.com/baigewallet?s=11&t=923EjGby7wY-_wUadSwtwA"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter className={styles.icon} />
            </a>
            <a href="/">
              <LinkedIn className={styles.icon} />
            </a>
            <a href="/">
              <Facebook className={styles.icon} />
            </a>
            <a href="https://youtube.com/@BaigeWallet" target="_blank" rel="noreferrer">
              <Youtube className={styles.icon} />
            </a>
          </div>
        </div>
      </section>

      <p>Copyright &copy; BaigeWallet {new Date().getFullYear()}</p>
      <hr />

      <div className={styles.copyrights}>
        <p>© 2022 BaigeWallet Incorporated | Disclosures | Accessibility </p>

        {/* <p>
          This website is operated by baigecapital.com , an SEC Registered
          Investment Advisor. Brokerage services are provided to clients of
          Acorns Advisers by Acorns Securities, LLC, an SEC registered
          broker-dealer and member FINRA. Member of SIPC. Securities in your
          account protected up to $500,000. For details, please see
          https://www.sipc.org. Checking accounts and debit cards are issued by
          Lincoln Savings Bank, Member FDIC.
        </p>

        <p>
          Important Disclosures: Investing involves risk, including loss of
          principal. Past performance does not guarantee or indicate future
          results. Please consider, among other important factors, your
          investment objectives, risk tolerance and Acorns pricing before
          investing. Diversification and asset allocation do not guarantee a
          profit, nor do they eliminate the risk of loss of principal. It is not
          possible to invest directly in an index. Any hypothetical performance
          shown is for illustrative purposes only. Such results do not represent
          actual results and do not take into consideration economic or market
          factors which can impact performance. Actual clients may achieve
          investment results materially different from the results portrayed.
          Round-Ups® investments are transferred from your linked funding source
          (checking account) to your Baige Invest account, where the funds are
          invested into a portfolio of selected ETFs. If you do not maintain an
          adequate amount of funds in your funding source sufficient to cover
          your Round-Ups® investments, you could incur overdraft fees with your
          financial institution. Only purchases made with a funding source
          linked to your Baige account with the feature active are eligible for
          the Round- Ups® feature. Please note that a properly suggested
          portfolio recommendation is dependent upon current and accurate
          financial and risk profiles. Clients who have experienced changes to
          their goals, financial circumstances or investment objectives, or who
          wish to modify their portfolio recommendation, should promptly update
          their information in the Baige app or through the website. Baige
          capital rewards investments are made by Baige Grow, Inc. into your
          Baige Invest account through a partnership Baige wallet Grow maintains
          with each Baige wallet partner. Baige wallet Fees are assessed based
          on the tier of services in which you are enrolled. Baige does not
          charge transactional fees, commissions or fees based on assets for
          accounts under $1 million. Baige Checking clients are not charged
          overdraft fees, maintenance fees, or ATM fees for cash withdrawals
          from in-network ATMs. Please see your Baige Subscription Center or
          Account Statements for a description of the fees you pay to Baige for
          its services. Third Party Quotes shown may not be representative of
          the experience of Baige customers and do not represent a guarantee of
          future performance or success. Please click on each testimonial to
          review the context from which this quote was taken. Baige, Round-Ups,
          Real-Time Round-Ups, Invest the Change and the Baige logo are
          registered trademarks of Baige Grow Incorporated. All product and
          company names are trademarks™ or registered® trademarks of their
          respective holders. Use of them does not imply any affiliation with or
          endorsement by them. Baige reserves the right to restrict or revoke
          any and all offers at any time.
        </p>

        <p>
          Baige does not provide access to invest directly in Bitcoin. Bitcoin
          exposure is provided through the ETF BITO, which invests in Bitcoin
          futures. This is considered a high-risk investment given the
          speculative and volatile nature. Further information and risks
          available via the disclosure link above.
        </p>

        <p>
          Compounding, generally, is the growth of principal investments due to
          the reinvestment of dividends without withdrawing funds from the
          account. Baige investment accounts do not pay interest, so the impact
          of compounding may be limited. It is not an investing strategy and
          does not assure positive performance nor does it protect against
          losses. It does not take into account market volatility and
          fluctuations that will impact the value of any investment account.
        </p>

        <p>
          The ETFs comprising the portfolios charge fees and expenses that will
          reduce a client’s return. Investors should consider the investment
          objectives, risks, charges and expenses of the funds carefully before
          investing. Investment policies, management fees and other information
          can be found in the individual ETF’s prospectus. Please read the
          prospectus carefully before you invest.
        </p>

        <p>
          Early Payday depends on timing of the submission of the payment file
          from the payer and fraud prevention restrictions. Funds are generally
          available on the day the payment file is received, up to 2 days
          earlier than the scheduled payment date. Timing may vary. Baige also
          offers an baige Checking deposit account.Baige Checking accounts are
          issued by Lincoln Savings Bank, Member FDIC, and are FDIC insured up
          to $250,000. Acorns Visa™ debit cards are issued by Lincoln Savings
          Bank, member FDIC for Baige Checking account holders. "Save and
          Invest" claim refers to a client's ability to utilize the Baige
          Checking Real-Time Round-up feature to invest small amounts of money
          from purchases made using an Baige Checking account, and seamlessly
          investing those small amounts using an Acorns Investment account.
          Requires both an active Baige Checking account and an Baige Investment
          account in good standing. Real-Time Round-Ups® are accrued instantly
          for investment during the next trading window.
        </p>

        <p>
          For additional important risks, disclosures and information, please
          visit https://www.baigewallet.com/terms/
        </p> */}
      </div>
    </footer>
  );
};

export default Footer;
