import { useLayoutEffect } from "react";
import HomeFrame from "./components/Homeframe";
import Home from "./pages/Home";
import TermsPage from "./pages/Terms";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";

function App() {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <HomeFrame>
        <Home />
      </HomeFrame>
    </>
  );
}
export function Terms() {
  const ns = new URLSearchParams(useLocation().search).get("hf") === "noshow";

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures that this effect runs only once after the initial render

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      {ns ? (
        <div style={{ marginTop: "-80px" }}>
          <TermsPage />
        </div>
      ) : (
        <HomeFrame>
          <TermsPage />
        </HomeFrame>
      )}
    </>
  );
}

export default App;
