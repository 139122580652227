import React from "react";

import Nav from "../nav";
import Footer from "../footer";

const HomeFrame = ({ children }: { children: React.ReactNode }) => {
  return (
    <React.Fragment>
      <Nav />
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default HomeFrame;
