import React, { useState } from "react";
import styles from "./about.module.css";

import GroupPic from "../../../assets/images/group.png";
import BusinessCouple from "../../../assets/images/business-couple.png";
import { DownArrow } from "../../icons/actions";

const About = () => {
  // const types = ["Savings", "Investments"];
  const types = ["Savings"];
  const [active, setActive] = useState(types[0]);

  return (
    <React.Fragment>
      <section className={styles.abtContainer}>
        <div className={styles.buttonGroup}>
          {types.map((type) => (
            <button
              className={`${styles.tab} ${
                active === type ? `${styles.active}` : ""
              }`}
              key={type}
              onClick={() => setActive(type)}
            >
              {type}
            </button>
          ))}
        </div>

        <React.Fragment>
          {active === types[0] ? (
            <React.Fragment>
              <div className={styles.abtContents}>
                <h2>
                  Secure your savings & earn high returns with BaigeWallet
                </h2>
                <p>
                  Baigewallet is a digital financial service platform offering a
                  wide demography of users the access to premium savings and
                  investment options at highly competitive rates, whilst
                  prioritizing security and convenience in automating their
                  financial and wealth management decisions.
                </p>
                {/* <div className={styles.learn_more}>
                  <p>Learn More</p>
                  <span className={styles.downArrow}>
                    <DownArrow />
                  </span>
                </div> */}
              </div>

              <div className={styles.abtImg}>
                <img src={GroupPic} alt="group of people smiling" />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={styles.abtContents}>
                <h2>Earn more money with our online investments plan</h2>
                <p>
                  We have built a robust system to help you manage and increase
                  your income greatly. We have built a robust system to help you
                  manage and increase your income greatly
                </p>
                <div className={styles.learn_more}>
                  <p>Learn More</p>
                  <span className={styles.downArrow}>
                    <DownArrow />
                  </span>
                </div>
              </div>

              <div className={styles.abtImg}>
                <img src={BusinessCouple} alt="business couple wearing masks" />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </section>
    </React.Fragment>
  );
};

export { About };
