import { AppStore, GooglePlay } from "../../icons";
import { Text } from "../../Layout";
import styles from "./hero.module.css";

const HeroSection = () => {
  return (
    <div className={styles.hero_container}>
      <div className={styles.hero}>
        <div className={styles.hero_contents}>
          <h2 className={styles.hero_title}>
            <Text color="orange">Saving</Text> &
            <Text color="orange"> investing</Text> <br />
            made simple.
          </h2>
          <p className={styles.hero_subtitle}>Safe. Easy. Reliable.</p>

          {/* EXTERNAL LINKS TO APP/GOOGLE STORE */}
          <div className={styles.install_btns}>
            <a
              href="https://apps.apple.com/ng/app/baigewallet/id1658484814"
              target="_blank"
              rel="noreferrer"
            >
              <AppStore />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.baige.wallet&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              <GooglePlay />
            </a>
          </div>
        </div>

        {/* A CAROUSEL HERE */}
        <div className={styles.hero_slider}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/female-copywriter.png`}
            alt="Female copywriter smiling at her phone"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export { HeroSection };
